function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { LOCAL_STORAGE_KEY } from './constants';
import { createLocalStorage } from './createLocalStorage';
import { createRecentSearchesPlugin } from './createRecentSearchesPlugin';
import { search as defaultSearch } from './search';
export function createLocalStorageRecentSearchesPlugin(options) {
  var _getOptions = getOptions(options),
      key = _getOptions.key,
      limit = _getOptions.limit,
      transformSource = _getOptions.transformSource,
      search = _getOptions.search,
      subscribe = _getOptions.subscribe;

  var storage = createLocalStorage({
    key: [LOCAL_STORAGE_KEY, key].join(':'),
    limit: limit,
    search: search
  });
  var recentSearchesPlugin = createRecentSearchesPlugin({
    transformSource: transformSource,
    storage: storage,
    subscribe: subscribe
  });
  return _objectSpread(_objectSpread({}, recentSearchesPlugin), {}, {
    name: 'aa.localStorageRecentSearchesPlugin',
    __autocomplete_pluginOptions: options
  });
}

function getOptions(options) {
  return _objectSpread({
    limit: 5,
    search: defaultSearch,
    transformSource: function transformSource(_ref) {
      var source = _ref.source;
      return source;
    }
  }, options);
}