import { LOCAL_STORAGE_KEY_TEST } from './constants';

function isLocalStorageSupported() {
  try {
    localStorage.setItem(LOCAL_STORAGE_KEY_TEST, '');
    localStorage.removeItem(LOCAL_STORAGE_KEY_TEST);
    return true;
  } catch (error) {
    return false;
  }
}

export function getLocalStorage(_ref) {
  var key = _ref.key;

  if (!isLocalStorageSupported()) {
    return {
      setItem: function setItem() {},
      getItem: function getItem() {
        return [];
      }
    };
  }

  return {
    setItem: function setItem(items) {
      return window.localStorage.setItem(key, JSON.stringify(items));
    },
    getItem: function getItem() {
      var items = window.localStorage.getItem(key);
      return items ? JSON.parse(items) : [];
    }
  };
}